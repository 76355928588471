.react-tabs__tab-list {
  list-style: none;
  background: #fff;
  border: 1px solid #e7ebf3;
  border-width: 1px 0;
  margin-top: 0;
}

.react-tabs__tab {
  display: inline-block;
  padding: 15px;
  color: #96a3b6;
  cursor: pointer;
  transform: translateY(1px);
  margin-right: 0px;
  margin-bottom: 0;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom-color: rgba(#96a3b6, 0.4);
  }
}

.react-tabs__tab--selected {
  color: #79589f;
  border-bottom-color: #79589f;

  &:hover {
    border-bottom-color: #79589f;
  }
}
