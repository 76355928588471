$header-cell-hovered-background-color: white;
$row-hovered-background-color: #fbfbfd;

@import '~react-base-table/es/_BaseTable.scss';

.hk-table-header {
  color: #475366;
  font-weight: bold;
  text-transform: capitalize;
  background: white;
  &:hover {
    color: $brand-primary;
  }
}

.hk-table {
  border-radius: 4px;
  box-shadow: none;

  &.with-shadow {
    box-shadow: 0 0 0 1px rgba(89, 105, 129, 0.1),
      0 1px 3px 0 rgba(89, 105, 129, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .BaseTable__table {
    border-radius: 4px;
  }

  .BaseTable__header-cell-text {
    height: 100%;
    line-height: 40px;
    white-space: nowrap;
    user-select: none;
  }

  a.BaseTable__row {
    text-decoration: none;

    &:focus {
      outline: 1px dotted $gray;
      z-index: 20;
    }
  }

  .BaseTable__header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .BaseTable__table-main {
    outline: 0;
  }
}
