// Salesforce Sans

// Normal styles
@font-face {
  font-family: 'Salesforce Sans';
  font-style: 'normal';
  font-weight: normal;
  src: url('https://www.herokucdn.com/fonts/SalesforceSans-Regular.woff2')
      format('woff2'),
    url('https://www.herokucdn.com/fonts/SalesforceSans-Regular.woff')
      format('woff');
}

@font-face {
  font-family: 'Salesforce Sans';
  font-style: normal;
  font-weight: bold;
  src: url('https://www.herokucdn.com/fonts/SalesforceSans-Bold.woff2')
      format('woff2'),
    url('https://www.herokucdn.com/fonts/SalesforceSans-Bold.woff')
      format('woff');
}

@font-face {
  font-family: 'Salesforce Sans';
  font-style: normal;
  font-weight: 300;
  src: url('https://www.herokucdn.com/fonts/SalesforceSans-Light.woff2')
      format('woff2'),
    url('https://www.herokucdn.com/fonts/SalesforceSans-Light.woff')
      format('woff');
}

@font-face {
  font-family: 'Salesforce Sans';
  font-style: normal;
  font-weight: 100;
  src: url('https://www.herokucdn.com/fonts/SalesforceSans-Thin.woff2')
      format('woff2'),
    url('https://www.herokucdn.com/fonts/SalesforceSans-Thin.woff')
      format('woff');
}

// Italic styles
@font-face {
  font-family: 'Salesforce Sans';
  font-style: italic;
  font-weight: 'normal';
  src: url('https://www.herokucdn.com/fonts/SalesforceSans-Italic.woff2')
      format('woff2'),
    url('https://www.herokucdn.com/fonts/SalesforceSans-Italic.woff')
      format('woff');
}

@font-face {
  font-family: 'Salesforce Sans';
  font-style: italic;
  font-weight: bold;
  src: url('https://www.herokucdn.com/fonts/SalesforceSans-BoldItalic.woff2')
      format('woff2'),
    url('https://www.herokucdn.com/fonts/SalesforceSans-BoldItalic.woff')
      format('woff');
}

@font-face {
  font-family: 'Salesforce Sans';
  font-style: italic;
  font-weight: 300;
  src: url('https://www.herokucdn.com/fonts/SalesforceSans-LightItalic.woff2')
      format('woff2'),
    url('https://www.herokucdn.com/fonts/SalesforceSans-LightItalic.woff')
      format('woff');
}

@font-face {
  font-family: 'Salesforce Sans';
  font-style: italic;
  font-weight: 100;
  src: url('https://www.herokucdn.com/fonts/SalesforceSans-ThinItalic.woff2')
      format('woff2'),
    url('https://www.herokucdn.com/fonts/SalesforceSans-ThinItalic.woff')
      format('woff');
}
