/* autoprefixer grid: autoplace */
@import './colors.scss';
@import './fonts.scss';
@import './reactTabs.scss';
@import './tachyons-flexbox.css';
@import './purple3_candidates.scss';
@import './monaco.scss';
@import './dataclip.scss';
@import './hk-table.scss';
@import '~@heroku/react-hk-components/dist/umd/react-hk-components.css';

html {
  height: 100%;
}

html,
body {
  font-family: 'Salesforce Sans', sans-serif;
}

* {
  -webkit-font-smoothing: subpixel-antialiased !important;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
  }
}

.app-container {
  display: flex;
  flex: 1 0 auto;
  min-height: 0;
  flex-direction: column;
}

#belostick {
  flex-shrink: 0;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;

  .glostick-active .glostick {
    .glostick {
      // TODO-IE11 uncomment
      // flex: 0 0 var(--glostick-total-height);
      // TODO-IE11 delete this line
      flex: 0 0 50px;
    }
  }
  // TODO-IE11: delete this block
  .glostick-active--helmet-active .glostick {
    .glostick {
      flex: 0 0 77px;
    }
  }
}

.header-shadow {
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.03);
}

.color-inherit {
  fill: currentColor;
}

.bg-white-ns {
  @media screen and (min-width: 30em) {
    background-color: white;
  }
}

.minw-7-l {
  @media screen and (min-width: 60em) {
    min-width: 240px;
  }
}

.expensive-query-code-block {
  min-height: 28em;
  margin: 0;
}

.postgresExpensiveQueriesSection {
  position: relative;
}

.dataclip-name-col {
  max-width: 240px;
}

.dataclip-datastore-col {
  min-width: 240px;
  width: 300px;
}

.dataclip-time-col {
  min-width: 120px;
}

.scrollable-schema-list {
  flex: 1 1 auto;
  overflow: auto;
  min-height: 0px;
  height: 300px;
  @media screen and (min-width: 60em) {
    height: 100%;
  }
}

.token-highlight {
  color: inherit;
  background: #fdf9d9;
  padding: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
