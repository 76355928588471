body.dataclip-detail-v2 {
  @media screen and (min-width: 60em) {
    .app-container {
      flex: 1;
    }
  }
}

body.dataclip-detail-v2 {
  .result-wrap {
    height: 450px;
    @media screen and (min-width: 60em) {
      height: 100%;
    }
  }
}

.outer-dc-grip-wrap {
  @media screen and (min-width: 60em) {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
  }
}

.dataclip-grid {
  @media screen and (min-width: 60em) {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 75px;
    grid-template-rows: 1fr;
    overflow-y: scroll;

    &.with-explorer {
      grid-template-columns: minmax(0, 1fr) 300px;
      grid-template-rows: 1fr;
    }
  }
}

.datastore-picker-error {
  box-shadow: 0 0 0px 1px red;
  border-radius: 4px;
}

.with-explorer {
  .schema-explorer {
    height: 300px;

    @media screen and (min-width: 60em) {
      height: 100%;
    }
  }
}

.btn-schema-explorer {
  @media screen and (min-width: 60em) {
    transform: rotate(-90deg);
  }
}

.postgres-chooser__menu {
  @media screen and (min-width: 60em) {
    min-width: 700px;
  }
}

.schema-explorer-scroll {
  flex: 1;
  flex-direction: column;
  display: flex;
  overflow: auto;
}
