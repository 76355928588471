body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.impersonation-fab {
  box-sizing: border-box;
  position: fixed;
  bottom: 20px;
  cursor: pointer;
  z-index: 999;
  padding: 6px 20px 7px;
  border-radius: 20px;
  left: 50%;
  width: 220px;
  margin-left: -110px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
}
