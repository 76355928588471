$brand-primary: #79589f;
$hero-color: #534a93;

// Blue
$brand-info: #56cdfc;

// Green
$brand-success: #74c080;

// Yellow
$brand-warning: #fa9f47;

// Red
$brand-danger: #d64242;

// Grays
$gray-lighter: #e7e7ec;
$gray-light: #cbcbd2;
$gray: #7d7d8e;
$gray-dark: #3f3f44;

// as sampled from dashboard
$gray-bg-light: #f5f5f5;
$gray-bg-cardHeader: #f8f8f9;
$gray-bg: #f7f8fb;
