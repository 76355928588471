$black: #000;
$near-black: #4e535a;
$dark-gray: #596981;
$mid-gray: #96a3b6;
$gray: #96a3b6;
$light-gray: #cfd7e6;
$silver: #e3e7ef;
$light-silver: #eef1f6;
$lightest-silver: #f7f8fb;
$moon-gray: #ccc;
$near-white: #fbfbfd;
$white: #fff;

$brand: #79589f;
$highlight: #56cdfc;
$success: #74c080;
$warning: #fa9f47;

$well-gray: #eef1f6;

$transparent: transparent;

$black-90: rgba(0, 0, 0, 0.9);
$black-80: rgba(0, 0, 0, 0.8);
$black-70: rgba(0, 0, 0, 0.7);
$black-60: rgba(0, 0, 0, 0.6);
$black-50: rgba(0, 0, 0, 0.5);
$black-40: rgba(0, 0, 0, 0.4);
$black-30: rgba(0, 0, 0, 0.3);
$black-20: rgba(0, 0, 0, 0.2);
$black-10: rgba(0, 0, 0, 0.1);
$black-05: rgba(0, 0, 0, 0.05);
$black-025: rgba(0, 0, 0, 0.025);
$black-0125: rgba(0, 0, 0, 0.0125);

$white-90: rgba(255, 255, 255, 0.9);
$white-80: rgba(255, 255, 255, 0.8);
$white-70: rgba(255, 255, 255, 0.7);
$white-60: rgba(255, 255, 255, 0.6);
$white-50: rgba(255, 255, 255, 0.5);
$white-40: rgba(255, 255, 255, 0.4);
$white-30: rgba(255, 255, 255, 0.3);
$white-20: rgba(255, 255, 255, 0.2);
$white-10: rgba(255, 255, 255, 0.1);
$white-05: rgba(255, 255, 255, 0.05);
$white-025: rgba(255, 255, 255, 0.025);
$white-0125: rgba(255, 255, 255, 0.0125);

$dark-red: #e7040f;
$red: #d64242;
$light-red: #e78e8e;
$orange: #fa9f47;
$light-orange: #fcc691;
$gold: #ffb700;
$yellow: #ffd700;
$light-yellow: #fbf1a9;
$purple: #79589f;
$light-purple: #a996c0;
$dark-pink: #d5008f;
$hot-pink: #ff41b4;
$pink: #ff80cc;
$light-pink: #ffa3d7;
$dark-green: #137752;
$green: #74c080;
$light-green: #aad8b3;
$navy: #001b44;
$dark-blue: #00449e;
$blue: #408fec;
$light-blue: #8cbcf3;
$lightest-blue: #f6faff;
$washed-blue: #f6fffe;
$washed-green: #e8fdf5;
$washed-yellow: #fffceb;
$washed-red: #ffdfdf;
